<template>
  <div id="app">
    <NavigationBar />

    <DefaultFormLayout />

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import NavigationBar from 'chimera/all/themes/waffle/components/layout/NavigationBar'
import Footer from 'chimera/all/themes/waffle/components/layout/Footer'
import DefaultFormLayout from 'chimera/all/themes/waffle/layouts/DefaultFormLayout'

export default {
  name: 'FormLayout',

  components: {
    DefaultFormLayout,
    Footer,
    NavigationBar,
  },
}
</script>
